export const Actions = {
  View: "v",
  Create: "c",
  Edit: "e",
  Delete: "d",
  Propose: "p",
  Request: "r",
  Convert: "cv",
  Refresh: "rf",
  Approve: "a",
  Unapprove: "u",
  Submit: "s",
  Export: "ex",
  Import: "i",
  Credit: "cr",
};

export const Targets = {
  User: "u",
  Role: "r",
  Action: "act",
  Currency: "cr",
  ExchangeRate: "ex",
  Customer: "cu",
  ResourceRole: "rr",
  Industry: "i",
  Discipline: "d",
  DisciplineType: "dt",
  BillingArea: "ba",
  Country: "ct",
  Commodity: "cm",
  Client: "cl",
  Resource: "rs",
  ResourceCertificate: "rc",
  ResourceUser: "ru",
  ClientOffice: "co",
  ClientContact: "cc",
  Office: "o",
  Vendor: "v",
  EpcContractor: "ec",
  VendorSite: "vs",
  VendorContact: "vc",
  Project: "p",
  Enquiry: "e",
  EnquiryDocument: "ed",
  EnquiryShortlistedResource: "esr",
  ProjectType: "pt",
  ClientType: "clt",
  CertificateType: "ctt",
  ResourceDocument: "rd",
  ResourceExperience: "re",
  Agency: "a",
  AgencyContact: "ac",
  ProjectContact: "pc",
  ClientDocument: "cd",
  Contract: "con",
  Job: "j",
  JobAssignment: "ja",
  JobTimesheet: "jt",
  ApprovedJobTimesheet: "ajt",
  ResourceContract: "rct",
  JobDocument: "jd",
  JobContact: "jc",
  JobResource: "jr",
  JobResourceAssignmentDocument: "jrad",
  ResourceContractRate: "rcr",
  ContractDocument: "cond",
  WorkOrder: "wo",
  ContractInvoicing: "ci",
  ContractInvoicingRolesAndDisciplines: "cird",
  ClientContractRate: "ccr",
  GlobalInvoicing: "gi",
  GlobalRate: "gr",
  Report: "rpt",
  EnquiryReport: "ert",
  ContractTechnicalAndQualityAssuranceInstructions: "ctqai",
  ContractComplianceInstructions: "cci",
  ContractFinancialInstructions: "cfi",
  ContractCommercialsInstructions: "ccmi",
  EndUser: "eu",
  ContractInvoicingInvoiceInstructions: "ciii",
  ProjectTechnicalAndQualityAssuranceInstructions: "ptqai",
  ProjectComplianceInstructions: "pci",
  ProjectFinancialInstructions: "pfi",
  ProjectCommercialsInstructions: "pcmi",
  OtherUsersActions: "ouact",
  JobTechnicalAndQualityAssuranceInstructions: "jtqai",
  JobComplianceInstructions: "jci",
  JobFinancialInstructions: "jfi",
  JobCommercialsInstructions: "jcmi",
  ProjectInvoicingInvoiceInstructions: "piii",
  JobReport: "jar",
  JobVendor: "jv",
  JobInvoicingInvoiceInstructions: "jiii",
  JobInvoicing: "ji",
  JobInvoicingRolesAndDisciplines: "jird",
  JobBudget: "jb",
  NonComplianceReportType: "ncrt",
  JobVendorNonComplianceReport: "jvncr",
  ScopeRejectionReason: "srr",
  ExpenseType: "et",
  Expense: "exp",
  PublicHoliday: "ph",
  ResourceStatus: "rst",
  ResourceQuickSearch: "rqs",
  JobRate: "jrt",
  ContractResourcesInstructions: "cri",
  ProjectResourcesInstructions: "pri",
  JobResourcesInstructions: "jri",
  AgencyDocument: "ad",
  ClientUser: "cu",
  VisitReport: "vrt",
  ResourceReport: "rrt",
  WorkOrderReport: "wrt",
  ContractReport: "crt",
  ReadyToInvoice: "rti",
  ProjectDocument: "pd",
  JobVendorPurchaseOrder: "jvpo",
  ContractBudget: "cb",
  OfficeBankAccount: "oba",
  TestReport: "trpt",
  TaxRate: "tr",
  DraftInvoice: "di",
  ContractContact: "ctc",
  ProjectInvoicingCustomFields: "picf",
  CommittedInvoice: "cmi",
  CreditNote: "cn",
  ContractInvoicingBillingOffices: "cibo",
};
