import { Form } from "@redriver/cinnamon-mui";
import { getSystemCustomFieldTypes } from "./actions";

const SystemCustomFieldDropDown = ({ existingFieldTypes = [], ...props }) => (
  <Form.Dropdown
    {...props}
    lookupAction={getSystemCustomFieldTypes}
    lookupParams={{ existingFieldTypes }}
  />
);

export default SystemCustomFieldDropDown;
