export const AppRoutes = {
  Root: "/",
  Admin: "/admin",
  Clients: "/clients",
  Enquiries: "/enquiries",
  Jobs: "/jobs",
  Resources: "/resources",
  Agencies: "/agencies",
  ResourceSearch: "/resource-search",
  Finance: "/finance",
  Reports: "/reports",
  Vendors: "/vendors",
  Projects: "/projects",
  Contracts: "/contracts",
  Profile: "/profile",
  Dashboard: "/dashboard",
  Assignments: "/assignments",
  GlobalSearch: (query?: string) =>
    `/search${query ? "?q=" + encodeURIComponent(query) : ""}`,
};

export const SystemRoutes = {
  Login: "/login",
  Logout: "/logout",
  SetupUser: "/setup-user/:token",
  VerifyEmail: "/verify-email/:token",
  ForgottenPassword: "/forgotten-password",
  ResetPassword: "/reset-password/:token",
  Account: "/account",
  Actions: "/actions",
  AzureAdSignIn: "/azure-ad-sign-in",
};

export const AdminRoutes = {
  Users: `${AppRoutes.Admin}/users`,
  Roles: `${AppRoutes.Admin}/roles`,
  Lookups: `${AppRoutes.Admin}/lookups`,
  Countries: `${AppRoutes.Admin}/countries`,
  Offices: `${AppRoutes.Admin}/offices`,
  Commodities: `${AppRoutes.Admin}/commodities`,
  CertificateTypes: `${AppRoutes.Admin}/certificate-types`,
  GlobalRates: `${AppRoutes.Admin}/global-rates`,
  GlobalInvoicing: `${AppRoutes.Admin}/global-invoicing`,
  TaxRates: `${AppRoutes.Admin}/tax-rates`,
};

export const ClientRoutes = {
  Client: (clientId = ":clientId") => `${AppRoutes.Clients}/${clientId}`,
  Contacts: (clientId?: string) => `${ClientRoutes.Client(clientId)}/contacts`,
  Projects: (clientId?: string) => `${ClientRoutes.Client(clientId)}/projects`,
  Enquiries: (clientId?: string) =>
    `${ClientRoutes.Client(clientId)}/enquiries`,
  Documents: (clientId?: string) =>
    `${ClientRoutes.Client(clientId)}/documents`,
  Contracts: (clientId?: string) =>
    `${ClientRoutes.Client(clientId)}/contracts`,
  WorkOrders: (clientId?: string) =>
    `${ClientRoutes.Client(clientId)}/work-orders`,
  Jobs: (clientId?: string) => `${ClientRoutes.Client(clientId)}/jobs`,
  Invoicing: (clientId?: string) =>
    `${ClientRoutes.Client(clientId)}/invoicing`,
};

export const RelativeContractRoutes = {
  Contract: (contractId = ":contractId") => `${contractId}`,
  Instructions: "instructions",
  Invoicing: "invoicing",
  Rates: "rates",
  Projects: "projects",
  Documents: "documents",
  Enquiries: "enquiries",
  WorkOrders: "work-orders",
  Jobs: "jobs",
  Budget: "budget",
  Contacts: "contacts",
};

export const RelativeProjectRoutes = {
  Project: (projectId = ":projectId") => `${projectId}`,
  Contacts: "contacts",
  Enquiries: "enquiries",
  Instructions: "instructions",
  Invoicing: "invoicing",
  Jobs: "jobs",
  Documents: "documents",
};

export const RelativeEnquiryRoutes = {
  Enquiry: (enquiryId = ":enquiryId") => `${enquiryId}`,
  Documents: "documents",
  Scopes: "scopes",
  Scope: (scopeId?: string) =>
    `scopes/${RelativeEnquiryScopeRoutes.Scope(scopeId)}`,
};

export const RelativeEnquiryScopeRoutes = {
  Scope: (scopeId = ":scopeId") => `${scopeId}`,
  Documents: "documents",
  Resources: "resources",
};

export const RelativeJobRoutes = {
  Job: (jobId = ":jobId") => `${jobId}`,
  Scopes: "scopes",
  Scope: (scopeId?: string) =>
    `scopes/${RelativeJobScopeRoutes.Scope(scopeId)}`,
  Assignments: "assignments",
  Assignment: (assignmentId?: string) =>
    `assignments/${RelativeJobAssignmentRoutes.Assignment(assignmentId)}`,
  Documents: "documents",
  Contacts: "contacts",
  Resources: "resources",
  Instructions: "instructions",
  Vendors: "vendors",
  Invoicing: "invoicing",
  Rates: "rates",
  Budget: "budget",
  Actions: "actions",
};

export const RelativeJobScopeRoutes = {
  Scope: (scopeId = ":scopeId") => `${scopeId}`,
};

export const RelativeJobResourceRoutes = {
  Resource: (jobResourceId = ":jobResourceId") => `${jobResourceId}`,
};

export const RelativeJobAssignmentRoutes = {
  Assignment: (assignmentId = ":assignmentId") => `${assignmentId}`,
};

export const OfficeRoutes = {
  Office: (officeId = ":officeId") => `${AdminRoutes.Offices}/${officeId}`,
  BankAccounts: (officeId?: string) =>
    `${OfficeRoutes.Office(officeId)}/bank-accounts`,
};

export const VendorRoutes = {
  Vendor: (vendorId = ":vendorId") => `${AppRoutes.Vendors}/${vendorId}`,
  Contacts: (vendorId?: string) => `${VendorRoutes.Vendor(vendorId)}/contacts`,
};

export const RelativeResourceRoutes = {
  Resource: (resourceId = ":resourceId") => `${resourceId}`,
  Experience: "experience",
  Certificates: "certificates",
  CV: "cv",
  Documents: "documents",
  Contract: "contract",
};

export const AgencyRoutes = {
  Agency: (agencyId = ":agencyId") => `${AppRoutes.Agencies}/${agencyId}`,
  Documents: (agencyId?: string) =>
    `${AgencyRoutes.Agency(agencyId)}/documents`,
  Resources: (agencyId?: string) =>
    `${AgencyRoutes.Agency(agencyId)}/resources`,
};

export const RelativeWorkOrderRoutes = {
  WorkOrder: (workOrderId = ":workOrderId") => `${workOrderId}`,
};

export const ReportRoutes = {
  EnquiryReport: `${AppRoutes.Reports}/enquiry`,
  VisitReport: `${AppRoutes.Reports}/visit`,
  ResourceReport: `${AppRoutes.Reports}/resource`,
  WorkOrderReport: `${AppRoutes.Reports}/work-orders`,
  ContractReport: `${AppRoutes.Reports}/contracts`,
};

export const ResourceUserRoutes = {
  EnquiryScope: (enquiryScopeId = ":enquiryScopeId") =>
    `${AppRoutes.Enquiries}/${enquiryScopeId}`,
  JobAssignment: (jobAssignmentId = ":jobAssignmentId") =>
    `${AppRoutes.Assignments}/${jobAssignmentId}`,
};

export const SignalRRoutes = {
  RefreshData: `signalr/refresh-data`,
};

export const ClientUserEnquiryRoutes = {
  Enquiry: (enquiryId = ":enquiryId") => `${AppRoutes.Enquiries}/${enquiryId}`,
  Documents: (enquiryId?: string) =>
    `${ClientUserEnquiryRoutes.Enquiry(enquiryId)}/documents`,
};

export const RelativeClientUserEnquiryScopeRoutes = {
  Scope: (enquiryScopeId = ":enquiryScopeId") => `scopes/${enquiryScopeId}`,
  Documents: "documents",
  Resources: "resources",
};

export const ClientUserJobRoutes = {
  Job: (jobId = ":jobId") => `${AppRoutes.Jobs}/${jobId}`,
  Documents: (jobId?: string) => `${ClientUserJobRoutes.Job(jobId)}/documents`,
  Contacts: (jobId?: string) => `${ClientUserJobRoutes.Job(jobId)}/contacts`,
  Budget: (jobId?: string) => `${ClientUserJobRoutes.Job(jobId)}/budget`,
  Resources: (jobId?: string) => `${ClientUserJobRoutes.Job(jobId)}/resources`,
};

export const RelativeClientUserJobScopeRoutes = {
  Scope: (jobScopeId = ":jobScopeId") => `scopes/${jobScopeId}`,
};

export const FinanceRoutes = {
  ReadyToInvoice: `${AppRoutes.Finance}/ready-to-invoice`,
  Currencies: `${AppRoutes.Finance}/currencies`,
  ExchangeRates: `${AppRoutes.Finance}/exchange-rates`,
  DraftInvoices: `${AppRoutes.Finance}/draft-invoices`,
  CommittedInvoices: `${AppRoutes.Finance}/committed-invoices`,
  CreditNotes: `${AppRoutes.Finance}/credit-notes`,
};

export const DraftInvoiceRoutes = {
  DraftInvoice: (draftInvoiceId = ":draftInvoiceId") =>
    `${FinanceRoutes.DraftInvoices}/${draftInvoiceId}`,
  Lines: (draftInvoiceId?: string) =>
    `${DraftInvoiceRoutes.DraftInvoice(draftInvoiceId)}/lines`,
  Documents: (draftInvoiceId?: string) =>
    `${DraftInvoiceRoutes.DraftInvoice(draftInvoiceId)}/documents`,
};

export const CommittedInvoiceRoutes = {
  CommittedInvoice: (committedInvoiceId = ":committedInvoiceId") =>
    `${FinanceRoutes.CommittedInvoices}/${committedInvoiceId}`,
  Lines: (committedInvoiceId?: string) =>
    `${CommittedInvoiceRoutes.CommittedInvoice(committedInvoiceId)}/lines`,
  Documents: (committedInvoiceId?: string) =>
    `${CommittedInvoiceRoutes.CommittedInvoice(committedInvoiceId)}/documents`,
};

export const CreditNoteRoutes = {
  CreditNote: (creditNoteId = ":creditNoteId") =>
    `${FinanceRoutes.CreditNotes}/${creditNoteId}`,
  Lines: (creditNoteId?: string) =>
    `${CreditNoteRoutes.CreditNote(creditNoteId)}/lines`,
  Documents: (creditNoteId?: string) =>
    `${CreditNoteRoutes.CreditNote(creditNoteId)}/documents`,
};
