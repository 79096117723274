import { DocumentViewType, OfficeType } from "constants/enums";
import { DateTimeFormats } from "constants/luxon";
import { DateTime } from "luxon";
import {
  ContractStatus,
  SystemCustomFieldType,
} from "mortar/EnergyEngineering/Enums";
import * as lookups from "mortar/endpoints/lookups";

export const getClients = ({ search }: { search: string }) =>
  lookups.clientsLookup({
    queryParams: {
      search,
      maxResults: 10,
      clientId: null,
    },
  });

export const getClientContacts = ({
  clientId,
  clientOfficeIds,
  jobId,
  projectId,
  search,
}: {
  clientId: string;
  clientOfficeIds: string[];
  jobId: string;
  projectId: string;
  search: string;
}) =>
  lookups.clientContactsLookup({
    queryParams: {
      clientId,
      clientOfficeIds,
      clientContactId: null,
      jobId,
      projectId,
      search,
      maxResults: 10,
    },
  });

export const getClientOffices = ({
  clientId,
  type,
  search,
}: {
  clientId: string | null;
  type: OfficeType | null;
  search: string;
}) =>
  lookups.clientOfficesLookup({
    queryParams: {
      clientId,
      search,
      maxResults: 10,
      type,
      clientOfficeId: null,
    },
  });

export const getContracts = ({
  clientId,
  includeExpired,
  contractId,
  status,
  enquiryId,
  search,
}: {
  clientId: string;
  includeExpired: boolean;
  contractId: string | null;
  status: ContractStatus[];
  enquiryId: string | null;
  search: string;
}) =>
  lookups.contractsLookup({
    queryParams: {
      clientId,
      includeExpired,
      search,
      maxResults: 10,
      contractId,
      enquiryId,
      status,
    },
  });

export const getProjects = ({
  clientId,
  clientOfficeId,
  contractId,
  search,
}: {
  clientId: string;
  clientOfficeId: string;
  contractId: string | null;
  search: string;
}) =>
  lookups.projectsLookup({
    queryParams: {
      clientId,
      clientOfficeId,
      projectId: null,
      contractId,
      search,
      maxResults: 10,
    },
  });

export const getVendors = ({ search }: { search: string }) =>
  lookups.vendorsLookup({
    queryParams: {
      search,
      maxResults: 10,
      vendorId: null,
    },
  });

export const getVendorSites = ({
  vendorId,
  search,
}: {
  vendorId: string;
  search: string;
}) =>
  lookups.vendorSitesLookup({
    queryParams: {
      vendorId,
      search,
      maxResults: 10,
      vendorSiteId: null,
    },
  });

export const getVendorContacts = ({
  vendorId,
  vendorSiteId,
  search,
}: {
  vendorId: string;
  vendorSiteId: string;
  search: string;
}) =>
  lookups.vendorContactsLookup({
    queryParams: {
      vendorId,
      vendorSiteId,
      search,
      maxResults: 10,
    },
  });

export const getEnquiries = ({ search }: { search: string }) =>
  lookups.enquiriesLookup({
    queryParams: {
      search,
      maxResults: 10,
    },
  });

export const getEnquiryScopes = ({
  enquiryId,
  search,
}: {
  enquiryId: string;
  search: string;
}) =>
  lookups.enquiryScopesLookup({
    queryParams: {
      enquiryId,
      search,
      maxResults: 10,
    },
  });

export const getDocumentCategories = ({
  viewType,
}: {
  viewType: DocumentViewType;
}) =>
  lookups.documentCategoriesLookup({
    queryParams: {
      viewType,
      categoryIds: null,
      excludeCategoryIds: null,
    },
  });

export const getResources = ({ search }: { search: string }) =>
  lookups.resourcesLookup({
    queryParams: {
      search,
      maxResults: 10,
    },
  });

export const getJobs = ({
  contractId,
  search,
}: {
  contractId: string | null;
  search: string;
}) =>
  lookups.jobsLookup({
    queryParams: {
      contractId,
      search,
      maxResults: 10,
    },
  });

export const getJobScopes = ({
  jobIds,
  vendorId,
  excludeResourceId,
  contractId,
  search,
}: {
  jobIds: string[];
  vendorId: string | null;
  excludeResourceId: number | null;
  contractId: string | null;
  search: string;
}) =>
  lookups.jobScopesLookup({
    queryParams: {
      jobIds,
      search,
      maxResults: 10,
      vendorId,
      excludeResourceId,
      contractId,
    },
  });

export const getJobResources = ({
  jobId,
  jobScopeId,
  jobAssignmentId,
  search,
}: {
  jobId: string;
  jobScopeId: string;
  jobAssignmentId: string;
  search: string;
}) =>
  lookups.jobResourcesLookup({
    queryParams: {
      jobId,
      jobScopeId,
      jobAssignmentId,
      search,
      maxResults: 10,
    },
  });

export const getWorkOrders = ({
  clientId,
  contractId,
  search,
}: {
  clientId: string;
  contractId: string | null;
  search: string;
}) =>
  lookups.workOrdersLookup({
    queryParams: {
      search,
      clientId,
      maxResults: 10,
      contractId,
    },
  });

export const getRateSets = ({
  contractId,
  jobId,
  includeExpired,
  includeExpiredRateSetId,
  includeGlobalRates,
  search,
}: {
  contractId: string;
  jobId: string;
  includeExpired: boolean;
  includeExpiredRateSetId: string | null;
  includeGlobalRates: boolean;
  search: string;
}) =>
  lookups.rateSetsLookup({
    queryParams: {
      contractId,
      jobId,
      includeExpired,
      includeExpiredRateSetId,
      includeGlobalRates,
      search,
      maxResults: 10,
    },
  });

export const getRates = ({
  contractId,
  enquiryScopeId,
  includeExpired,
  search,
}: {
  contractId: string;
  enquiryScopeId: string;
  includeExpired: boolean;
  search: string;
}) =>
  lookups.ratesLookup({
    queryParams: {
      contractId,
      enquiryScopeId,
      includeExpired,
      search,
      maxResults: 10,
    },
  });

export const getJobAssignments = ({
  jobScopeId,
  excludeFutureVisits,
  search,
}: {
  jobScopeId: string;
  excludeFutureVisits: boolean;
  search: string;
}) =>
  lookups.jobAssignmentsLookup({
    queryParams: {
      jobScopeId,
      search,
      maxResults: 10,
      maxVisitDate: excludeFutureVisits
        ? DateTime.now().toFormat(DateTimeFormats.LocalDate)
        : null,
    },
  });

export const getJobAssignmentVisits = ({
  jobAssignmentId,
  maxVisitDate,
  jobReportId,
  documentCategoryId,
}: {
  jobAssignmentId: string;
  maxVisitDate: string;
  jobReportId: string;
  documentCategoryId: number;
}) =>
  lookups.jobAssignmentVisitsLookup({
    queryParams: {
      jobAssignmentId,
      maxVisitDate,
      jobReportId,
      documentCategoryId,
      maxResults: 100,
    },
  });

export const getJobLocations = ({
  jobId,
  search,
}: {
  jobId: string;
  search: string;
}) =>
  lookups.jobLocationsLookup({
    queryParams: {
      jobId,
      search,
      maxResults: 10,
    },
  });

export const getResourceRateSets = ({
  resourceId,
  search,
}: {
  resourceId: number;
  search: string;
}) =>
  lookups.resourceRateSetsLookup({
    queryParams: {
      resourceId,
      search,
      maxResults: 10,
    },
  });

export const getJobReports = ({
  jobId,
  jobAssignmentId,
  search,
}: {
  jobId: string;
  jobAssignmentId: string;
  search: string;
}) =>
  lookups.jobReportsLookup({
    queryParams: {
      jobId,
      jobAssignmentId,
      maxResults: 10,
      search,
    },
  });

export const getAgencies = ({ search }: { search: string }) =>
  lookups.agenciesLookup({
    queryParams: {
      search,
      maxResults: 10,
    },
  });

export const getSystemCustomFieldTypes = ({
  existingFieldTypes,
}: {
  existingFieldTypes: SystemCustomFieldType[];
}) =>
  lookups.systemCustomFieldTypeLookup({
    queryParams: {
      existingFieldTypes,
    },
  });
